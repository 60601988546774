function App() {
  return (
    <div className="bg-gray-800 h-full ">
      <div class="text-center pt-20 text-7xl  font-extrabold leading-none tracking-tight">
        <span class="decoration-clone bg-clip-text text-transparent bg-gradient-to-b from-blue-300 to-blue-500">
          Hello
          World
        </span>
      </div>
      <div className="flex justify-center mt-8">
        <div className="bg-blue-100 font-mono max-w-3xl rounded-lg p-10 text-gray-800">
          <div className="text-3xl font-bold ">Hassan Bazzi</div>
          <div className="pt-2">
            I am a motivated Software Engineer and Entrepreneur with a passion for building things. I started Hostodo in December of 2014, a virtual server hosting company that provides virtual machines and containers to clients globally. I currently work at Trust & Will as a Senior Software Engineer II.
          </div>
          {/* <div className="font-bold mt-5">
            <a href="/hassan-bazzi-resume.pdf" className="hover:text-gray-600 underline">Download my resume</a>
          </div> */}
          <div className="font-bold mt-5">
            me@hassanb.com
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-3 pb-60 my-auto">
        <div className="bg-blue-100 font-mono max-w-3xl rounded-lg p-10 text-gray-800">
          I am available for Software Engineering work and consulting. You may reach me via email at me@hassanb.com
          <br /> <br />
          <a className="hover:underline" href="https://linkedin.com/in/hassan-bazzi">LinkedIn Profile</a>
        </div>
      </div>
    </div>
  );
}

export default App;
